import { getInitialState as sourceGetInitialState } from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import {
    UPDATE_INFO_LOAD_STATUS,
    UPDATE_PRODUCT_LIST_COUNTER,
    UPDATE_PRODUCT_LIST_INFO,
} from 'Store/ProductListInfo/ProductListInfo.action';

/** @namespace Pwa/Store/ProductListInfo/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    counter: {},
});

/** @namespace Pwa/Store/ProductListInfo/Reducer/reduceFilters */
export const reduceFilters = (filters) =>
    filters.reduce((co, item) => {
        const {
            request_var: attribute_code,
            name: attribute_label,
            position: attribute_position,
            filter_items,
            facet_max_size,
            frontend_input,
            frontend_class,
            slider_display_pattern,
            is_boolean,
            has_swatch,
        } = item;

        const { attribute_values, attribute_options } = filter_items.reduce(
            (attribute, option, index) => {
                const { value_string } = option;
                const { attribute_values, attribute_options } = attribute;

                attribute_values.push(value_string);

                return {
                    ...attribute,
                    attribute_options: {
                        ...attribute_options,
                        [value_string]: { ...option, index },
                    },
                };
            },
            { attribute_values: [], attribute_options: {} }
        );

        return {
            ...co,
            [attribute_code]: {
                attribute_code,
                attribute_label,
                attribute_position,
                attribute_values,
                attribute_type: 'select',
                attribute_options,
                facet_max_size,
                frontend_input,
                frontend_class,
                slider_display_pattern,
                is_boolean,
                has_swatch,
            },
        };
    }, {});
/** @namespace Pwa/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case UPDATE_PRODUCT_LIST_INFO: {
            const {
                selectedFilter,
                products: { filters: availableFilters = [], min_price, max_price, sort_fields: sortFields } = {},
            } = action;

            return {
                ...state,
                filters: reduceFilters(availableFilters),
                sortFields,
                minPrice: Math.floor(min_price),
                maxPrice: Math.ceil(max_price),
                isLoading: false,
                selectedFilter,
            };
        }

        case UPDATE_INFO_LOAD_STATUS: {
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };
        }

        case UPDATE_PRODUCT_LIST_COUNTER: {
            const { counter } = action;

            return {
                ...state,
                counter,
            };
        }

        default:
            return state;
    }
};

export default ProductListReducer;
